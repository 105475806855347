<template>

<div class="result-listing__item lowest-price position-relative">
    <div class="result-listing__item__block overflow-hidden">
      <div class="result-listing__item__block-top">
        <div class="row g-0">
          <div class="col-lg-12 col-md-12">
            <div class="result-listing__item__block_left h-100" style="border:0 !important">
              <div class="result-listing__item__block_top">
                <div
                  class="result-listing__item__block_top_row d-flex flex-wrap align-items-center"
                >
                  <div class="partner-content text-center">
                    <div class="partner-content_title">
                      <h5 class="mb-0">
                        {{ $t('looking_for_allrisk_plan') }}
                      </h5>
                      <p> {{ $t('no_quotes_manual_quote_title') }}
                        <a href="https://api.whatsapp.com/send?phone=96171396654" target="_blank" style="color:#0061FF">
                          <!-- <img height="25" width="25" src="@/assets/images/icons/whatsapp_footer.png"> -->
                          {{ $t('click_here_to_whatsapp_us') }}
                        </a>
                        <br />
                        <!-- <a :href="url">{{ $t('no_quotes_another_car') }}</a> -->
                      </p>
                    </div>
                    <div class="partner-content_bottom d-flex"></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      
    </div>
  </div>


</template>
<script>
  import {
    defineComponent,
    computed
  } from "vue";


  export default defineComponent({


    setup() {

      const customer_detatils = JSON.parse(window.localStorage.getItem('customer_detatils'));

      const url = computed(() => {
        const vehicleDetailsBasePath = (process.env.VUE_APP_FOLDER !== 'undefined') ? `/${process.env.VUE_APP_FOLDER}/` : '/';
        return (customer_detatils?.value?.customer_source_url) ?
          (customer_detatils.value.customer_source_url) :
          (vehicleDetailsBasePath + 'vehicle-details');
      })

      return {
        url,
        customer_detatils
      };
    },
  });
</script>
<style>
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: 14.5px !important;
    line-height: 20px !important;
  }
</style>